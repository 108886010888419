import React from "react";
import tw from "twin.macro";

// ========= COMPONENTS =========
import ImageCard from "../templates/ImageCard";

// ========= ASSETS =========
import copy from "../../../assets/copy";
import cardImageURL from "../../../assets/img/Check Back Card.png";

// ========= ICONS =========
import ShareButton from "../../Elements/ShareButton";

// ========= MAIN =========
export default function EndCard(props) {
	return (
		<ImageCard
			{...props}
			imageURL={cardImageURL}
			bg={tw`bg-white`}
		>
			<div
				css={[
					tw`absolute w-full flex justify-start px-5 pb-5`,
				]}
			>
				<ShareButton
					closeColor={tw`text-projectBlue-900`}
					headerText={copy.endCard.button}
					subtitleText={copy.endCard.subtitle}
					headerColor={tw`text-red`}
					subtitleColor={tw`text-projectBlue-900`}
					contentURL={process.env.SITE_URL}
				/>
			</div>
		</ImageCard>
	);
}
