import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";

// ========= UTILS =========
import copy from "../../assets/copy";
import { ENUMS } from "../../libs/globals";

// ========= ANIMATION =========
import { motion, useGestures } from "framer-motion";
const transition = {
	delay: 0.5,
	duration: 0.25,
	ease: `linear`,
	type: `tween`,
};

// ========= ICONS =========
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faInfoCircle as infoIcon,
	faTimesCircle as closeIcon,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as CheckIcon } from "../../assets/img/Check Circle.svg";
import { ReactComponent as SadIcon } from "../../assets/img/Frown Circle.svg";

// ========= COMPONENTS =========
import { Scrollbars } from "react-custom-scrollbars";
import Card from "./templates/BaseCard";
import Sharebutton from "../Elements/ShareButton";
import Confetti from "../Elements/Confetti";
import BlockContent from "@sanity/block-content-to-react";
import { getQuestionStats } from "../../libs/auth";

const Wrapper = tw.div`
	relative
	w-full h-full
	p-5 pt-20
	flex flex-col justify-start items-center
	pointer-events-none
	text-center
	z-50
`;

// the block at the top of the card that contains the correct state, icon, and feedback
const FeedbackBlock = ({ correct, answerFeedback, percentage }) => (
	<Wrapper>
		{correct ? <CheckIcon /> : <SadIcon />}
		<h1
			css={[
				tw`text-2xl uppercase font-bold`,
				correct && tw`text-yellow-text`,
				!correct && tw`text-pink-text`,
			]}
		>
			{correct
				? copy.answered.correct
				: copy.answered.incorrect}
		</h1>
		<h2 css={[tw`text-white text-sm mt-4`]}>
			{answerFeedback}
		</h2>
		<h2 css={[tw`text-projectGreen-400 text-sm mt-4`]}>
			{percentage}% responded correctly
		</h2>
	</Wrapper>
);

const LearnMore = ({ setLearnMore, learnType, learnLink }) => {
	const style = tw`
		absolute z-50 top-0 right-0 m-4
		text-white text-xs
		cursor-pointer pointer-events-auto
	`;

	if (learnType === ENUMS.LEARN_LINKS.LINK)
		return (
			<a
				href={learnLink}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div css={style}>
					{copy.answered.learn}
					{` `}
					<FontAwesomeIcon icon={infoIcon} />
				</div>
			</a>
		);

	return (
		<div
			css={style}
			onClick={() => {
				setLearnMore(true);
			}}
		>
			{copy.answered.learn}
			{` `}
			<FontAwesomeIcon icon={infoIcon} />
		</div>
	);
};

// the initial content of the card
const CardFeedback = ({
	setLearnMore,
	correct,
	answerFeedback,
	confettiIsDone,
	setConfettiIsDone,
	learnType,
	learnLink,
	slug,
	percentage,
}) => (
	<>
		{confettiIsDone && (
			<LearnMore
				setLearnMore={setLearnMore}
				learnLink={learnLink}
				learnType={learnType}
			/>
		)}

		<FeedbackBlock
			{...{ correct, answerFeedback, percentage }}
		/>

		{!correct ? (
			<div css={tw`absolute w-full bottom-0 p-4`}>
				<Sharebutton
					contentURL={`${process.env.SITE_URL}/quiz/?q=${slug}`}
				/>
			</div>
		) : (
			<div css={tw`absolute w-full h-full bottom-0`}>
				{!confettiIsDone ? (
					<Confetti
						handleAnimationComplete={() =>
							setConfettiIsDone(true)
						}
					/>
				) : (
					<div css={tw`absolute w-full bottom-0 p-4`}>
						<Sharebutton
							contentURL={`${process.env.SITE_URL}/quiz/?q=${slug}`}
						/>
					</div>
				)}
			</div>
		)}
	</>
);

// the fullscreen content of the card
const FullscreenFeedback = ({
	learnMore,
	setLearnMore,
	height,
	correct,
	answerFeedback,
	learnCategory,
	learnContent,
	slug,
}) => {
	return (
		<motion.div
			css={[
				css`
					margin-top: -100px;
				`,
				tw`
					w-full max-w-xl md:max-w-2xl opacity-0
					relative overflow-y-scroll rounded-lg
				`,
			]}
			variants={{
				expanded: {
					opacity: 1,
					transition: {
						delay: 0.5,
						duration: 0.25,
						ease: `linear`,
						type: `tween`,
					},
				},
			}}
			animate={learnMore ? `expanded` : ``}
		>
			{/* Close button */}
			<div css={tw`flex flex-col`}>
				{learnMore}
				<div
					css={[
						tw`
							absolute top-0 right-0 m-4
							text-white text-xs
							cursor-pointer pointer-events-auto
						`,
					]}
					//
					onClick={() => {
						// go back to the card
						setLearnMore(false);
					}}
				>
					<FontAwesomeIcon icon={closeIcon} size="2x" />
				</div>

				{/* Answer Feedback Block */}
				<FeedbackBlock {...{ correct, answerFeedback }} />

				{/* How to spot it */}
				<div
					css={tw`
						w-full bg-projectBlue-700
						rounded-button p-5 mb-4
						text-white
					`}
				>
					<p tw="italic leading-none">
						{copy.answered.how}
					</p>
					<p tw="font-bold uppercase text-xl leading-none mb-4">
						{learnCategory.replace(`_`, ` `)}
					</p>
					{/* <p tw="text-xs mt-2">{learnContent}</p> */}
					<BlockContent
						css={[
							css`
								& > * {
									margin: 1rem 0;
								}
								a {
									${tw`underline`}
								}
							`,
						]}
						renderContainerOnSingleChild
						blocks={JSON.parse(
							JSON.stringify(learnContent).replace(
								`blockImage`,
								`image`
							)
						)}
						serializers={{
							marks: {
								link: ({ mark, children }) => {
									// Read https://css-tricks.com/use-target_blank/
									const { href } = mark;
									return (
										<a
											href={href}
											target="_blank"
											rel="noreferrer"
										>
											{children}
										</a>
									);
								},
							},
						}}
					/>
				</div>
				<Sharebutton
					contentURL={`${process.env.SITE_URL}/quiz/?q=${slug}`}
				/>
			</div>
		</motion.div>
	);
};

// ========= MAIN =========
const AnsweredCard = ({
	slug,
	correct, // if the answer was correct
	answerFeedback, // the feedback to display at the top of the card
	learnType, // the type of feedback content
	learnLink, // the link to direct via the learn more button
	learnCategory, // the category to display above the content
	learnContent, // the content itself
	width, // card width
	height, // card height
	...props
}) => {
	const [learnMore, setLearnMore] = useState(false);
	const [confettiIsDone, setConfettiIsDone] = useState(false);

	const [percentage, setPercentage] = useState();
	useEffect(() => {
		(async () => {
			const p = await getQuestionStats(slug);
			setPercentage(p);
		})();
	}, []);

	return (
		<Card
			key={slug}
			width={width}
			height={height}
			bg={tw`bg-projectBlue-1100`}
			expanded={learnMore}
			{...props}
		>
			{/* if in card mode, just show the truncated content */}
			{/* if in fullscreen mode, show the unabridged content */}
			{!learnMore ? (
				<CardFeedback
					{...{
						correct,
						answerFeedback,
						setLearnMore,
						confettiIsDone,
						setConfettiIsDone,
						learnType,
						learnLink,
						slug,
						percentage,
					}}
				/>
			) : (
				<FullscreenFeedback
					{...{
						learnMore,
						setLearnMore,
						height,
						correct,
						answerFeedback,
						learnCategory,
						learnContent,
						slug,
					}}
				/>
			)}
		</Card>
	);
};

export default AnsweredCard;
