import React from "react";
import tw from "twin.macro";

import Card from "../templates/BaseCard";

import { ReactComponent as IC } from "../../../assets/img/Intro Card.svg";

export default function IntroCard(props) {
	return (
		<Card {...props} bg={tw`bg-projectBlue-700`}>
			<div
				css={tw`
					w-full h-full 
					flex flex-col justify-end
				`}
			>
				<IC tw="absolute w-full h-full" />
			</div>
		</Card>
	);
}
