import React from "react";
import tw, { css } from "twin.macro";
import { Link, graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

// ========= COMPONENTS =========
import ImageCard from "../templates/ImageCard";
import TextButton from "../../Elements/Buttons/TextButton";

// ========= ASSETS =========
import copy from "../../../assets/copy";
import cardImageURL from "../../../assets/img/Sign Card.png";
import bgImageURL from "../../../assets/img/Red Card.png";
import { updatePlayerState } from "../../../libs/auth";

// ========= MAIN =========
export default function PledgeCard(props) {
	return (
		<ImageCard
			{...props}
			imageURL={cardImageURL}
			bg={css`
				background-image: url("${bgImageURL}");
			`}
		>
			<div
				css={[
					tw`absolute w-full flex justify-center`,
					css`
						bottom: 17%;
					`,
				]}
			>
				<Link
					to="/pledge"
					onClick={() => {
						updatePlayerState({
							skippedPledge: false,
						});
					}}
				>
					<TextButton
						color="BLACK"
						text={copy.pledgeCard.button}
					/>
				</Link>
			</div>
		</ImageCard>
	);
}
