import React, { useEffect, useRef } from "react";
import tw from "twin.macro";

import Lottie from "lottie-web";
import * as animationData from "../../assets/lottie/confetti.json";

const Confetti = ({ handleAnimationComplete = () => {} }) => {
	const ref = useRef();
	useEffect(() => {
		const anim = Lottie.loadAnimation({
			animationData: animationData.default,
			container: ref.current,
			renderer: `svg`,
			loop: false,
			autoplay: true,
		});

		anim.addEventListener(`complete`, handleAnimationComplete, {
			once: true,
		});
	}, []);

	return (
		<div
			ref={ref}
			css={[tw`w-full h-full pointer-events-none`]}
		/>
	);
};

export default Confetti;
