import React, { useEffect, useState } from "react";

export default function setCarousel({
	setCards,
	cardDeck,
	currentQuestionIndex,
}) {
	// whenever the current question changes
	useEffect(() => {
		// if the current question isn't set (like when the page first loads empty), just break out
		if (!cardDeck) return;

		// set the carousel with the previous, current, and next card (based on the index)
		// previous and next cards are turned inactive
		let nextAfterIndex = currentQuestionIndex + 2;
		if (nextAfterIndex >= cardDeck.length - 2) {
			nextAfterIndex = 1;
		}
		setCards({
			current: React.cloneElement(
				cardDeck[currentQuestionIndex],
				{ active: true }
			),
			next: React.cloneElement(
				cardDeck[currentQuestionIndex + 1],
				{ active: false }
			),
			nextAfter: React.cloneElement(
				cardDeck[nextAfterIndex],
				{ active: false }
			),
		});
	}, [currentQuestionIndex, cardDeck]);
}
