import React, { useState, useEffect } from "react";
import tw, { css } from "twin.macro";

// ========= FORMS =========
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// ========= COMPONENTS =========
import BaseCard from "../templates/BaseCard";
import TextButton from "../../Elements/Buttons/TextButton";
import OptIn from "../../Elements/OptIn";

// ========= ASSETS =========
import copy from "../../../assets/copy";
import bgImageURL from "../../../assets/img/Red Card.png";
import logoURL from "../../../assets/img/brand/PenLogo.png";
import {
	sendPasswordlessEmail,
	updatePlayerState,
} from "../../../libs/auth";

// ========= MAIN =========
export default function RegisterCard(props) {
	const { register, handleSubmit } = useForm({
		resolver: yupResolver(
			yup.object().shape({
				email: yup.string().email().required(),
			})
		),
	});

	const [error, setError] = useState(false);

	const [emailOptIn, setEmailOptIn] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	const onSubmitEmail = ({ email }) => {
		sendPasswordlessEmail({ email });

		updatePlayerState({ newsletter: emailOptIn });

		if (emailOptIn)
			fetch(
				`https://us-central1-pen-america-quiz.cloudfunctions.net/subscribeToNewsletter?email=${email}`,
				{ mode: `no-cors` }
			);

		setEmailSent(true);
	};

	return (
		<BaseCard
			{...props}
			bg={css`
				background-image: url("${bgImageURL}");
			`}
		>
			<div
				css={[
					tw`
					absolute w-full h-full 
					flex flex-col justify-between items-center
					p-5 text-center font-centra text-white
					pt-20
					`,
				]}
			>
				<h1
					css={tw`
					text-3xl uppercase text-white font-bold leading-6 mb-3
				`}
				>
					{copy.registerCard.heading}
				</h1>

				{!emailSent ? (
					<>
						<h3 css={tw`font-bold mb-3`}>
							{copy.registerCard.subheading}
						</h3>

						<form
							onSubmit={handleSubmit(onSubmitEmail)}
						>
							<input
								css={[
									tw`
								shadow appearance-none
								border-4 border-transparent rounded-full
								w-full p-2 px-3
								text-gray-900 leading-tight italic text-sm
								focus:outline-none focus:shadow-outline
							`,
									error &&
										tw`border-yellow border-4`,
								]}
								name="email"
								id="email"
								type="text"
								placeholder={
									copy.registerCard
										.fieldPlaceholder
								}
								ref={register({
									required: true,
								})}
							/>

							{error && (
								<span
									css={[
										tw`text-xs`,
										error
											? tw`visible`
											: tw`invisible`,
									]}
								>
									{copy.registerCard.error}
								</span>
							)}

							<div>
								<OptIn
									text={copy.registerCard.optIn}
									setState={(optIn) => {
										setEmailOptIn(optIn);
										// setFormState({
										// 	...formState,
										// 	optIn,
										// });
									}}
								/>
							</div>

							<div tw="mb-5">
								<TextButton
									color="BLACK"
									text={
										copy.registerCard
											.actionButton
									}
									type="submit"
									// onClick={onSubmit}
								/>
							</div>
						</form>
					</>
				) : (
					<h2 tw="text-base">
						{copy.signUp.instructions}
					</h2>
				)}

				<img
					src={logoURL}
					alt="PEN America"
					css={tw`w-1/5`}
				/>
			</div>
		</BaseCard>
	);
}
