import { getQuestionFromCard } from "../libs/quizHelpers";

export default ({ questions, currentQuestionIndex, cardDeck }) => {
	if (!cardDeck) return {};

	const currentKey = getQuestionFromCard({
		card: cardDeck[currentQuestionIndex],
		questions,
	})?.slug.current;

	const previousKey = getQuestionFromCard({
		card: cardDeck[currentQuestionIndex - 1],
		questions,
	})?.slug.current;

	const nextKey = getQuestionFromCard({
		card: cardDeck[currentQuestionIndex + 1],
		questions,
	})?.slug.current;

	return { currentKey, previousKey, nextKey };
};
