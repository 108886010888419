import React, { useState } from "react";
import tw, { css } from "twin.macro";

import BlankCard from "../Cards/templates/BaseCard";

// ========= UTILS =========
import {
	ASPECT_RATIO,
	CARD_SLIVER,
	CARD_MARGIN,
	BASE_WIDTH,
	BASE_HEIGHT,
} from "../../libs/globals";
import { AnimatePresence } from "framer-motion";

// ========= HOOKS =========
import useDimensions from "react-cool-dimensions";
import { ResizeObserver } from "@juggle/resize-observer";
import HeaderBar from "./HeaderBar";

// ========= CUSTOM COMPONENTS =========
const Carousel = React.forwardRef(({ children }, ref) => (
	<div
		ref={ref}
		css={[
			tw`
				relative
				flex items-center flex-col
				w-full h-full
				`,
		]}
	>
		{children}
	</div>
));

// ========= MAIN =========
export default ({
	current,
	next,
	nextAfter,
	handlePrevious,
	handleNext = () => {},
	when,
}) => {
	const [[cardWidth, cardHeight], setCardSize] = useState([0, 0]);

	// get a ref of the window
	const { ref } = useDimensions({
		polyfill: ResizeObserver,
		// whenever the page resizes
		onResize: ({ width, height }) => {
			// set the card width based on the height of the carousel
			let h = height;
			let w = (1 / ASPECT_RATIO) * h;

			// if the screen is too narrow
			if (2 * CARD_MARGIN + w > width) {
				// adjust the width to fit the screen
				w = width - 2 * CARD_MARGIN;
				// adjust the height to match the width
				h = ASPECT_RATIO * w;
			}

			const correctedHeight = Math.min(height * 0.7, h);
			const correctedWidth = Math.min(
				(1 / ASPECT_RATIO) * correctedHeight,
				w
			);

			setCardSize([
				Math.min(
					Math.max(Math.floor(w), BASE_WIDTH * 0.75),
					BASE_WIDTH * 1.5
				),
				Math.min(
					Math.max(Math.floor(h), BASE_HEIGHT * 0.75),
					BASE_HEIGHT * 1.5
				),
			]);
		},
	});

	// ========= RENDER =========
	return (
		<>
			{/* <div css={tw`absolute flex justify-center w-full h-0`}>
				<div
					css={[
						tw`absolute bottom-0`,
						css`
							width: ${cardWidth}px;
						`,
					]}
				>
					<HeaderBar when={when} />
				</div>
			</div> */}

			<div
				css={[
					tw`w-full h-full flex flex-col items-center mb-20 md:mb-0`,
				]}
			>
				<Carousel ref={ref}>
					<div
						css={[
							tw`-mt-20 mb-4 md:mb-8`,
							css`
								width: ${cardWidth}px;
							`,
						]}
					>
						<HeaderBar when={when} width={cardWidth} />
					</div>
					<div css={tw`relative`}>
						{/* TEMP: BLANK CARD */}
						<AnimatePresence initial={false}>
							<BlankCard
								// key="previous"
								width={cardWidth}
								height={cardHeight}
								gestureHandlers={{
									tap: () => {},
									swipeRight: handleNext,
									swipeLeft: handlePrevious,
								}}
								initial={{
									scale: 0,
									y: 0,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								}}
								animate={{
									scale: 0.9,
									y: 50,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								}}
							/>

							{/* next card / smaller card underneath */}
							{React.cloneElement(next, {
								// key: `next`,
								width: cardWidth,
								height: cardHeight,
								gestureHandlers: {
									tap: () => {},
									swipeRight: handleNext,
									swipeLeft: handlePrevious,
								},
								initial: {
									scale: 0,
									y: 0,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								},
								animate: {
									scale: 0.95,
									y: 25,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								},
								exitX: {
									x: 0,
									opacity: 0,
									scale: 0.5,
									rotate: -20,
									transition: { duration: 0.5 },
								},
							})}

							{/* current card */}
							{React.cloneElement(current, {
								// key: `current`,
								width: cardWidth,
								height: cardHeight,
								gestureHandlers: {
									tap: () => {},
									swipeRight: handleNext,
									swipeLeft: handlePrevious,
								},
								initial: {
									scale: 0,
									y: 0,
									rotate: 0,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								},
								animate: {
									scale: 1,
									y: 0,
									transition: {
										type: `ease-in`,
										duration: 0.1,
									},
								},
								exitX: {
									x: 0,
									opacity: 0,
									scale: 0.5,
									rotate: 20,
									transition: { duration: 0.5 },
								},
							})}
						</AnimatePresence>
					</div>
				</Carousel>
			</div>
		</>
	);
};
