import React from "react";
import tw, { css } from "twin.macro";

import { COLORS, ENUMS } from "../../../libs/globals";
import TextButton from "../../Elements/Buttons/TextButton";

const MultipleChoiceAnswers = ({
	answers,
	color,
	answerQuestion,
	slug,
}) => (
	<div>
		{answers.map((answer, i) => (
			<div
				key={`mc-${i}`}
				onClick={() => {
					answerQuestion({
						questionKey: slug,
						isCorrect: answer.isCorrect ?? false,
					});
				}}
				css={[
					COLORS[color].smallAnswers,
					tw`
						rounded-button py-2 px-4 mt-2
						text-xs
						cursor-pointer
						pointer-events-auto
					`,
				]}
			>
				{answer.answer}
			</div>
		))}
	</div>
);

const ImageChoiceAnswers = ({
	answers,
	color,
	answerQuestion,
	slug,
}) => {
	const numAnswers = answers.length;
	return (
		<div
			css={[
				numAnswers === 4 &&
					css`
						${tw`grid grid-cols-2 grid-rows-2 gap-2`}
						height: 60%;
					`,
				numAnswers === 3 &&
					css`
						& > div {
							${tw`mt-3 h-full`}
							min-height: 0;
						}
						${tw`flex flex-col`}
						height: 60%;
					`,
				numAnswers === 2 &&
					css`
						& > div {
							${tw`mt-3 h-full`}
							min-height: 0;
						}
						${tw`flex flex-col`}
						height: 60%;
					`,
			]}
		>
			{answers.map((answer, i) => (
				<div
					key={`ic-${i}`}
					onClick={() => {
						answerQuestion({
							questionKey: slug,
							isCorrect: answer.isCorrect ?? false,
						});
					}}
					css={[
						COLORS[color].imageAnswers,
						tw`
							rounded-button p-4
							flex flex-col justify-center items-center text-center
							text-sm font-bold
							cursor-pointer
							pointer-events-auto
						`,
						css`
								background:
									url("${answer.answerImage?.asset.fixed.src}");
								}

								&:hover {
									opacity: 0.8;
								}
							`,
						answer?.answer &&
							css`
								background: linear-gradient(
										rgba(0, 0, 0, 0.25),
										rgba(0, 0, 0, 0.25)
									),
									url("${answer.answerImage?.asset
										.fixed.src}");

								&:hover {
									opacity: 1;
									background: linear-gradient(
											rgba(0, 0, 0, 0.45),
											rgba(0, 0, 0, 0.45)
										),
										url("${answer.answerImage
											?.asset.fixed.src}");
								}
							`,
						tw`bg-cover bg-center bg-no-repeat
						hover:bg-cover hover:bg-center hover:bg-no-repeat
						`,
					]}
				>
					{answer.answer}
				</div>
			))}
		</div>
	);
};

const TrueFalseAnswers = ({
	answers,
	color,
	answerQuestion,
	slug,
}) => (
	<div tw="pb-10">
		{answers.map((answer, i) => (
			<div
				key={`tf-${i}`}
				onClick={() => {
					answerQuestion({
						questionKey: slug,
						isCorrect: answer.isCorrect ?? false,
					});
				}}
				css={[
					COLORS[color].largeAnswers,
					tw`
						rounded-button py-2 px-4 mt-2
						text-xl font-bold text-center
						cursor-pointer
						pointer-events-auto
					`,
				]}
			>
				{answer.answer}
			</div>
		))}
	</div>
);

const FillBlankAnswers = ({
	answers,
	slug,
	answerQuestion,
	dropdownSelection,
}) => {
	const selectedAnswer = answers.find(
		(answer) => answer.answer === dropdownSelection
	);
	const isCorrect = selectedAnswer?.isCorrect ?? false;

	return (
		<div tw="pointer-events-auto mb-10">
			<TextButton
				text="Submit"
				onClick={() => {
					answerQuestion({
						questionKey: slug,
						isCorrect,
					});
				}}
			/>
		</div>
	);
};

const Answers = ({
	answers, // the array of answers
	color, // the question color
	questionType, // the question type
	answerQuestion, // callback to answer a question
	dropdownSelection, // the value of the optional dropdown
	slug, // the question ID
}) => {
	// swap out the component depending on the type of question
	let AnswerType;
	if (questionType === ENUMS.QUESTION_TYPES.MULTIPLE_CHOICE)
		AnswerType = MultipleChoiceAnswers;
	if (questionType === ENUMS.QUESTION_TYPES.IMAGE_CHOICE)
		AnswerType = ImageChoiceAnswers;
	if (questionType === ENUMS.QUESTION_TYPES.TRUE_FALSE)
		AnswerType = TrueFalseAnswers;
	if (questionType === ENUMS.QUESTION_TYPES.FILL_BLANK)
		AnswerType = FillBlankAnswers;

	// ========= RENDER =========
	return (
		<AnswerType
			answers={answers}
			color={color}
			answerQuestion={answerQuestion}
			dropdownSelection={dropdownSelection}
			slug={slug}
		/>
	);
};

export default Answers;
