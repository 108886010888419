import React from "react";
import tw from "twin.macro";

// ========= COMPONENTS =========
import Card from "./BaseCard";

// ========= MAIN =========
export default function ImageCard(props) {
	const { imageURL, alt = `Diagram`, children } = props;
	return (
		<Card {...props}>
			<div
				css={tw`
					w-full h-full
					flex flex-col justify-end
				`}
			>
				<img
					src={imageURL}
					alt={alt}
					css={tw`absolute w-full bottom-0 pointer-events-none`}
				/>
				{children}
			</div>
		</Card>
	);
}
