import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import hagen from "hagen";
import tw, { css } from "twin.macro";

// ========= UTILS =========
import { ENUMS } from "../libs/globals";
import copy from "../assets/copy";
import {
	getQuestionFromCard,
	handleNext,
	handlePrevious,
} from "../libs/quizHelpers";
import { getPledge, logEvent } from "../libs/auth";

// ========= HOOKS =========
import {
	useQueryParam,
	StringParam,
	withDefault,
} from "use-query-params";
import useLogin from "../hooks/useLogin";
import useDeck from "../hooks/useDeck";
import setCarousel from "../hooks/setCarousel";

// ========= COMPONENTS =========
import SEO from "../components/SEO";
import PageBackground from "../components/Elements/PageBackground";
import NavBar from "../components/Elements/NavBar";
import Carousel from "../components/Elements/Carousel";
import {
	SiteWrapper,
	BlankCard,
} from "../components/quizComponents";
import getQuizKeys from "../hooks/getQuizKeys";
import useGameState from "../hooks/useGameState";
import useCurrentQuestion from "../hooks/useCurrentQuestion";

// ========= MAIN =========
const Quiz = ({ data }) => {
	// ========= META =========
	// if user has started quiz
	const [startedQuiz, setStartedQuiz] = useState(false);

	// when the quiz is started, trigger an event
	useEffect(() => {
		if (startedQuiz)
			logEvent(`started_quiz`, { uid: user.uid });
	}, [startedQuiz]);

	// when the user is loaded, fire an event
	useEffect(() => {
		if (user) logEvent(`landed_quiz`, { uid: user.uid });
	}, [user]);

	// ========= PARAMS =========
	// look for a q key
	const [slug, setSlug] = useQueryParam(
		`q`,
		withDefault(StringParam, `intro`)
	);

	// look for a social key
	const [social] = useQueryParam(`social`, StringParam);

	// ========= PLAYER =========
	// make sure the user is logged in
	const user = useLogin();

	// when the user logs in, get the gameState from firebase
	const [
		gameState,
		answerQuestion,
		lastQuestionAnswered,
	] = useGameState({ user });

	// if the player has already answered questions, restore their position
	const [startIndex, setStartIndex] = useState(1);
	const [positionRestored, setPositionRestored] = useState(false);
	useEffect(() => {
		if (positionRestored) return;
		if (!lastQuestionAnswered) return;

		setStartIndex(
			Math.max(
				cardDeck?.findIndex(
					(card) => card.key === lastQuestionAnswered
				),
				1
			) + 1
		);
		setPositionRestored(true);
	}, [lastQuestionAnswered]);

	// ========= CARD DECK =========
	// get all the questions from the graphql query
	const questions = data.allSanityQuizQuestion.nodes;

	const [pledge, setPledge] = useState();
	useEffect(() => {
		if (!user) return;
		(async () => {
			const p = await getPledge(user.uid);
			setPledge(p);
		})();
	}, [user]);

	// create deck
	const cardDeck = useDeck({
		questions,
		hasSigned: !!pledge,
		social,
		isAnonymous: user?.isAnonymous,
	});

	// get and set current question
	const [
		currentQuestionIndex,
		setCurrentQuestionIndex,
	] = useCurrentQuestion({
		startIndex,
		cardDeck,
		slug,
		setSlug,
	});

	// set up the carousel state
	const [cards, setCards] = useState({
		current: <BlankCard />,
		next: <BlankCard />,
		nextAfter: <BlankCard />,
	});

	// whenever the current question changes update the carousel
	setCarousel({ setCards, cardDeck, currentQuestionIndex });

	// ========= CARD KEYS =========
	const { currentKey, previousKey, nextKey } = getQuizKeys({
		questions,
		currentQuestionIndex,
		cardDeck,
	});

	// ========= RENDER =========
	if (!cardDeck) return <></>;
	if (!gameState) return <></>;

	return (
		<SiteWrapper>
			<SEO
				title="Quiz"
				metaType={ENUMS.META_TYPES.QUIZ}
				social={{ key: currentKey }}
			/>

			<PageBackground />

			<div
				css={[
					tw`w-full z-50 lg:pl-48`,
					css`
						height: 70vh;
					`,
				]}
			>
				<Carousel
					current={React.cloneElement(cards.current, {
						answered: currentKey in gameState,
						correct: gameState[currentKey] ?? false,
						answerQuestion,
					})}
					next={React.cloneElement(cards.next, {
						answered: nextKey in gameState,
					})}
					nextAfter={React.cloneElement(cards.nextAfter, {
						answered: previousKey in gameState, // ToDo: update previousKey
					})}
					handlePrevious={() =>
						handlePrevious({
							currentQuestionIndex,
							setCurrentQuestionIndex,
							cardDeck,
						})
					}
					handleNext={() => {
						if (!startedQuiz) setStartedQuiz(true);
						return handleNext({
							currentQuestionIndex,
							setCurrentQuestionIndex,
							cardDeck,
						});
					}}
					//
					when={
						getQuestionFromCard({
							card: cardDeck[currentQuestionIndex],
							questions,
						})?.label || copy.labels.when.electing
					}
				/>
			</div>

			<NavBar activePage={ENUMS.NAV.QUIZ} />
		</SiteWrapper>
	);
};

export default Quiz;

// ========= GRAPHQL QUERY =========
export const query = graphql`
	{
		allSanityQuizQuestion(
			sort: { order: ASC, fields: launch }
		) {
			nodes {
				question
				questionType
				coverType
				answers {
					isCorrect
					answer
					answerImage {
						asset {
							fixed(width: 200) {
								src
							}
						}
					}
				}
				slug {
					current
				}
				launch
				label
				answerFeedback
				learnType
				learnTypes {
					_rawLearnInfo(
						resolveReferences: { maxDepth: 10 }
					)
					learnLink
				}
				coverTypes {
					bgVideo {
						asset {
							playbackId
						}
					}
					overflowImage {
						asset {
							url
						}
						alt
					}
				}
			}
		}
	}
`;
