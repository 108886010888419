import React from "react";

import tw, { css } from "twin.macro";

import Dropdown from "../../Elements/Dropdown";

import { ENUMS } from "../../../libs/globals";

const baseStyles = ({ coverType }) => [
	tw`text-xl font-bold leading-relaxed`,
	coverType === ENUMS.COVER_TYPES.COLOR && tw`text-xl`,
	coverType === ENUMS.COVER_TYPES.VIDEO && tw`text-xl text-white`,
	coverType === ENUMS.COVER_TYPES.OVERFLOW &&
		tw`text-base leading-snug`,
];

const MultipleChoiceQuestion = ({ question, coverType, color }) => (
	<h1 css={[...baseStyles({ coverType, color }), tw`px-4`]}>
		{question}
	</h1>
);

const ImageChoiceQuestion = ({ question, coverType }) => (
	<h1
		css={[
			...baseStyles({ coverType }),
			tw`text-base leading-snug px-4`,
		]}
	>
		{question}
	</h1>
);

const TrueFalseQuestion = ({ question, coverType }) => (
	<h1 css={[...baseStyles({ coverType }), tw`px-4`]}>
		{question}
	</h1>
);

const FillBlankQuestion = ({
	question,
	coverType,
	answers,
	color,
	setDropdownSelection,
}) => {
	const splits = question.split(ENUMS.BLANK_TOKEN);

	return (
		<div
			css={[
				...baseStyles({ coverType }),
				tw`pointer-events-auto`,
			]}
		>
			<h1 tw="mb-2 px-4">{splits[0]}</h1>
			<Dropdown
				options={answers}
				color={color}
				css={tw`pointer-events-auto`}
				setState={(selection) =>
					setDropdownSelection(selection.value)
				}
			/>
			<h1 tw="mt-2 px-4">{splits[1]}</h1>
		</div>
	);
};

const Question = ({
	coverType,
	question,
	questionType,
	answers,
	color,
	setDropdownSelection,
}) => {
	let QuestionType;
	if (questionType === ENUMS.QUESTION_TYPES.MULTIPLE_CHOICE)
		QuestionType = MultipleChoiceQuestion;
	if (questionType === ENUMS.QUESTION_TYPES.IMAGE_CHOICE)
		QuestionType = ImageChoiceQuestion;
	if (questionType === ENUMS.QUESTION_TYPES.TRUE_FALSE)
		QuestionType = TrueFalseQuestion;
	if (questionType === ENUMS.QUESTION_TYPES.FILL_BLANK)
		QuestionType = FillBlankQuestion;

	return (
		<QuestionType
			question={question}
			coverType={coverType}
			answers={answers}
			color={color}
			setDropdownSelection={setDropdownSelection}
		/>
	);
};

export default Question;
