import hagen from "hagen";
import { useEffect, useState } from "react";
import {
	getPlayerState,
	setQuestionStats,
	updatePlayerState,
} from "../libs/auth";

const useGameState = ({ user }) => {
	const [gameState, setGameState] = useState();
	const [
		lastQuestionAnswered,
		setLastQuestionAnswered,
	] = useState();

	// when the user is set
	useEffect(() => {
		if (!user) return;

		(async () => {
			// get the player state from firebase
			const ps = await getPlayerState();

			// set it locally
			setGameState(ps?.gameState ?? {});
			setLastQuestionAnswered(ps?.lastQuestionAnswered);
		})();
	}, [user]);

	// answer a question
	const answerQuestion = ({ questionKey, isCorrect }) => {
		const gs = {
			...gameState,
			[questionKey]: isCorrect,
		};

		// set the game state
		setGameState(gs);
		setLastQuestionAnswered(questionKey);

		// set stats
		setQuestionStats({ key: questionKey, isCorrect });
	};

	// when the game state updates
	useEffect(() => {
		if (!gameState) return;
		if (Object.keys(gameState).length === 0) return;

		// set the player state in firebase
		updatePlayerState({ gameState });
	}, [gameState]);

	// when the last question answered is updated
	useEffect(() => {
		if (!lastQuestionAnswered) return;
		// set the last answered question in firebase
		updatePlayerState({ lastQuestionAnswered });
	}, [lastQuestionAnswered]);

	return [gameState, answerQuestion, lastQuestionAnswered];
};

export default useGameState;
