import React from "react";
import tw, { css } from "twin.macro";

import BaseCard from "./Cards/templates/BaseCard";

// wrap the entire page with a div
export const SiteWrapper = tw.div`
	relative
	h-screen w-screen
	bg-projectBlue-800
	flex flex-col justify-center items-center
	overflow-hidden
`;

// a generic blank card for spacing purposes
export const BlankCard = (props) => (
	<BaseCard bg={false} shadow={false} {...props} />
);
