import React from "react";
import tw, { css } from "twin.macro";

// ========= UTILS =========
import getMuxPosterSrc from "../../../libs/getMuxPosterSrc";

// ========= COMPONENTS =========
import Card from "./BaseCard";
import MuxPlayer from "../../MuxPlayer";

// ========= MAIN =========
export default function VideoCard({
	videoAsset,
	hasPlayedCallback,
	isPlayingCallback,
	active,
	children,
	...props
}) {
	const source = `https://stream.mux.com/${videoAsset.playbackId}.m3u8`;
	const posterFrame = getMuxPosterSrc(videoAsset.playbackId, {
		time: 0,
		fitMode: `preserve`,
	});
	const finalFrame = getMuxPosterSrc(videoAsset.playbackId, {
		time: 9999999999999, // grab a frame way after the end of the video
		fitMode: `preserve`,
	});

	return (
		<Card
			{...props}
			bg={[
				tw`bg-cover`,
				css`
					background-image: url(${finalFrame});
				`,
			]}
		>
			{active && (
				<>
					<MuxPlayer
						source={source}
						posterFrame={posterFrame}
						finalFrame={finalFrame}
						hasPlayedCallback={hasPlayedCallback}
						isPlayingCallback={isPlayingCallback}
					/>
					{children}
				</>
			)}
		</Card>
	);
}
