import React, { useState, useEffect } from "react";
import tw, { css } from "twin.macro";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// ========= UTILS =========
import { ENUMS, COLORS } from "../../libs/globals";

// ========= COMPONENTS =========
import Card from "./templates/BaseCard";
import Question from "./QuestionCard/Question";
import Answers from "./QuestionCard/Answers";
import VideoCard from "./templates/VideoCard";

import AnsweredCard from "./AnsweredCard";

const Wrapper = tw.div`
	relative
	w-full h-full
	p-5 pt-20
	flex flex-col justify-between
	pointer-events-none
	z-50
`;

// ========= MAIN =========
// TODO: Add PropTypes. This is a mess.
const QuestionCard = ({
	// meta
	slug, // the question key
	width, // the card width
	height, // the card height
	active = true, // if the card should be rendered with or without content
	gestureHandlers = {
		tap: () => {},
		swipeRight: () => {},
		swipeLeft: () => {},
	}, // handlers for listening to taps and swipes
	answerQuestion = () => {}, // callback when answering a question
	// question
	question, // the question text
	answers, // the answers object
	questionType, // multiple choice? true/false? fill in the blank? image choice?
	// cover
	coverType, // color? overflow? video?
	color, // the card background color
	cover, // what to display on the card background
	// feedback
	answered = false, // has the card already been answered?
	correct, // was the question answered correctly?
	answerFeedback, // the feedback content for the response info
	learnType, // link? content?
	learnLink, // the external more info link
	learnCategory, // the type of feedback is in the response info
	learnContent, // the content for the response info
	initial,
	animate,
	cardTransition,
	exitX,
	setExit,
	drag,
}) => {
	// ========= CARD TYPE =========
	// pick a card type depending on if it's a video question or not
	let CardType;
	CardType =
		coverType === ENUMS.COVER_TYPES.VIDEO ? VideoCard : Card;
	// or if it has aleady been answered
	if (answered && active) CardType = AnsweredCard;

	// ========= VIDEO STATE =========
	// set up the video play state
	const [videoHasPlayed, setVideoHasPlayed] = useState(true);
	const [videoIsPlaying, setVideoIsPlaying] = useState(false);
	useEffect(() => {
		// if this is a video card, set this to false immediately so the question doesn't show
		if (coverType === ENUMS.COVER_TYPES.VIDEO)
			setVideoHasPlayed(false);
	}, []);

	// =========  =========
	const [dropdownSelection, setDropdownSelection] = useState();

	// =========  =========

	const data = useStaticQuery(graphql`
		query {
			allSanityQuizQuestion {
				nodes {
					coverTypes {
						overflowImage {
							asset {
								fluid {
									...GatsbySanityImageFluid
								}
							}
						}
					}
					slug {
						current
					}
				}
			}
		}
	`);

	const [overflowImage, setOverflowImage] = useState();
	useEffect(() => {
		if (!active) return;
		if (!data) return;

		const o = data.allSanityQuizQuestion.nodes.find((el) => {
			return el.slug.current === slug;
		});

		console.log(o);

		setOverflowImage(o.coverTypes?.overflowImage?.asset?.fluid);
	}, [slug, data, active]);

	// ========= RENDER =========

	return (
		<CardType
			key={slug}
			bg={[
				coverType === ENUMS.COVER_TYPES.COLOR &&
					COLORS[color].colorCard, // display the relevant background image if it's a COLOR card
				coverType === ENUMS.COVER_TYPES.OVERFLOW &&
					COLORS[color].overflowCard, // make the background white if it's a OVERFLOW card
				questionType ===
					ENUMS.QUESTION_TYPES.IMAGE_CHOICE &&
					COLORS[color].imageQuestionCard, // set the background white if it's an IMAGE_CHOICE card
			]}
			//
			videoAsset={cover}
			//
			hasPlayedCallback={(hasPlayed) => {
				setVideoHasPlayed(hasPlayed);
			}}
			isPlayingCallback={(isPlaying) => {
				setVideoIsPlaying(isPlaying);
			}}
			//
			{...{
				slug,
				width,
				height,
				active,
				gestureHandlers,
				learnCategory,
				learnContent,
				correct,
				answerFeedback,
				learnType,
				learnLink,
				answered,
				initial,
				animate,
				cardTransition,
				exitX,
				setExit,
				drag,
			}}
		>
			{/* if the card is active, show its contents */}
			{active && (
				<>
					{/* show contents when the video is done (or if it's not a video card) */}
					{videoHasPlayed && (
						<Wrapper>
							{/* display the overflow image if required */}
							{coverType ===
								ENUMS.COVER_TYPES.OVERFLOW && (
								<div
									css={[
										tw`w-full left-0 top-0`,
										css`
											margin-top: -7em;
											margin-bottom: 2rem;
											margin-left: -1.25rem;
											width: calc(
												100% + 2.5rem
											);
										`,
									]}
								>
									{overflowImage && (
										<Img
											fluid={overflowImage}
											alt={cover.alt}
										/>
									)}
								</div>
								// <img
								// 	src={cover.imageURL}
								// 	alt={cover.alt}
								// 	css={[
								// 		tw`w-full left-0 top-0`,
								// 		css`
								// 			margin-top: -7em;
								// 			margin-bottom: 2rem;
								// 		`,
								// 	]}
								// />
							)}

							<div
								css={[
									css`
										visibility: ${videoIsPlaying
											? `hidden`
											: `visible`};
									`,
								]}
							>
								<Question
									{...{
										question,
										answers,
										coverType,
										questionType,
										color,
										setDropdownSelection,
									}}
								/>
							</div>

							<div tw="flex-1" />

							<Answers
								{...{
									answers,
									color,
									questionType,
									slug,
									answerQuestion,
									dropdownSelection,
								}}
							/>
						</Wrapper>
					)}
				</>
			)}
		</CardType>
	);
};

export default QuestionCard;
