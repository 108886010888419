/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import tw, { css } from "twin.macro";
import NextIcon from "../../../assets/img/icons/icon-next-arrow.svg";
import PreviousIcon from "../../../assets/img/icons/icon-previous-arrow.svg";

// ========= ANIMATION =========
import {
	motion,
	useMotionValue,
	useTransform,
} from "framer-motion";

import {
	BASE_HEIGHT,
	BASE_WIDTH,
	EXPAND_SCALE,
} from "../../../libs/globals";

const transition = {
	type: `tween`,
	duration: 0.5,
	ease: `linear`,
};

// ========= CUSTOM COMPONENTS =========
const CardContainer = ({
	width,
	height,
	bg,
	shadow,
	expanded,
	tapHandler,
	swipeHandler,
	children,
}) => {
	const x = useMotionValue(0);
	const scale = useTransform(x, [-150, 0, 150], [0.5, 1, 0.5]);
	const rotate = useTransform(x, [-150, 0, 150], [-45, 0, 45], {
		clamp: false,
	});
	return (
		<motion.div
			layout
			css={[
				tw`
					flex justify-center items-center
					font-centra
					bg-cover select-none
					bg-projectBlue-800
				`,
				css`
					width: ${width}px;
					min-width: ${width}px;
					height: ${height}px;
					min-height: ${height}px;
				`,
				!expanded && tw`rounded-card`,
				bg,
				shadow,
			]}
			// Scale card container
			variants={{
				expanded: { scale: EXPAND_SCALE, transition },
				closed: {
					scale: 1,
					transition: {
						duration: 0,
					},
				},
				tapped: { scale: 0.98 },
			}}
			animate={expanded ? `expanded` : `closed`}
			style={{
				x,
				scale,
				rotate,
			}}
			drag="x"
			dragConstraints={{
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
			}}
			onDragEnd={(event, info) => {
				if (info.offset.x > 150) {
					swipeHandler(1); // Advance the deck
				}
				if (info.offset.x < -150) {
					swipeHandler(-1); // Go backwards
				}
			}}
			dragTransition={{
				bounceStiffness: 600,
				bounceDamping: 20,
			}}
		>
			{children}
		</motion.div>
	);
};

const InnerCard = ({ children, expanded }) => (
	<motion.div
		css={[
			tw`relative flex w-full h-full z-40`,
			!expanded && tw`rounded-card`,
		]}
		variants={{
			expanded: { scale: 1 / EXPAND_SCALE, transition },
			closed: {
				scale: 1,
				transition: {
					duration: 0,
				},
			},
		}}
		animate={expanded ? `expanded` : `closed`}
	>
		{children}
	</motion.div>
);

const ScaledCard = ({ width, children }) => {
	const scale = width / BASE_WIDTH;

	return (
		<div
			css={css`
				transform: scale(${scale * 0.9});
				transform-origin: top;
			`}
		>
			{children}
		</div>
	);
};

// ========= MAIN =========
export default function Card({
	width,
	height,
	active,
	gestureHandlers = {
		tap: () => {
			// console.log(`TAP`);
		},
		swipeRight: () => {
			// console.log(`SWIPE RIGHT`);
		},
		swipeLeft: () => {
			// console.log(`SWIPE LEFT`);
		},
	},
	bg = tw`bg-projectBlue-1100`,
	shadow = tw`shadow-2xl`,
	expanded = false,
	answered,
	children,
	initial,
	animate,
	exitX = {},
}) {
	// ========= RENDER =========
	if (!width && !height) return <></>;
	// console.log(`initial: ${initial}`);
	// console.log(`animate: ${animate}`);
	// console.log(`cardTransition: ${cardTransition}`);
	// console.log(`exitX: ${exitX}`);
	// console.log(`setExit: ${setExit}`);
	// console.log(`drag: ${drag}`);

	return (
		<div css={tw`relative`}>
			<motion.div
				css={[
					tw`absolute top-0 flex w-full justify-center`,
				]}
				initial={initial}
				animate={animate}
				transition={transition}
				exit={exitX}
			>
				<ScaledCard width={width}>
					<CardContainer
						width={BASE_WIDTH}
						height={BASE_HEIGHT}
						//
						bg={
							!answered ? bg : tw`bg-projectBlue-1100`
						}
						shadow={shadow}
						expanded={expanded}
						//
						tapHandler={() => {
							if (active) return; // only allow taps on the edge cards
							gestureHandlers.tap();
						}}
						swipeHandler={(direction) => {
							if (direction === -1) {
								gestureHandlers.swipeRight();
							} else {
								gestureHandlers.swipeLeft();
							}
						}}
					>
						<InnerCard expanded={expanded}>
							{children}
						</InnerCard>
					</CardContainer>
				</ScaledCard>
			</motion.div>
			<div
				css={[
					tw`hidden lg:flex justify-between items-center absolute w-32 h-32 mx-auto`,
					css`
						margin-left: -4rem;
						bottom: -85vh;
					`,
				]}
			>
				<img
					src={PreviousIcon}
					alt="Next Card"
					css={[tw`w-12 cursor-pointer`, css``]}
					onClick={() => {
						gestureHandlers.swipeLeft();
					}}
				/>
				<img
					src={NextIcon}
					alt="Next Card"
					css={[
						tw`w-12 cursor-pointer`,
						css`
							margin-left: 2em;
						`,
					]}
					onClick={() => {
						gestureHandlers.swipeRight();
					}}
				/>
			</div>
		</div>
	);
}
