export const handlePrevious = ({
	currentQuestionIndex,
	setCurrentQuestionIndex,
}) => {
	if (currentQuestionIndex > 1)
		setCurrentQuestionIndex(currentQuestionIndex - 1);
};

export const handleNext = ({
	currentQuestionIndex,
	setCurrentQuestionIndex,
	cardDeck,
}) => {
	if (currentQuestionIndex < cardDeck.length - 2)
		// ignore the start and end cards
		setCurrentQuestionIndex(currentQuestionIndex + 1);
};

export const getQuestionFromCard = ({ card, questions }) => {
	const key = card?.key ?? `intro`;
	return questions.find(
		(question) => question.slug.current === key
	);
};
