import React, { useEffect, useState } from "react";
import { isBefore, parseISO } from "date-fns";
import { ENUMS } from "../libs/globals";
import copy from "../assets/copy";

import QuestionCard from "../components/Cards/QuestionCard";
import IntroCard from "../components/Cards/BespokeCards/IntroCard";
import PledgeCard from "../components/Cards/BespokeCards/PledgeCard";
import RegisterCard from "../components/Cards/BespokeCards/RegisterCard";
import EndCard from "../components/Cards/BespokeCards/EndCard";

import { BlankCard } from "../components/quizComponents";

// create all the cards
export default function useDeck({
	questions,
	isAnonymous,
	hasSigned,
	social,
}) {
	const [deck, setDeck] = useState();

	useEffect(() => {
		// for each launched question, create a new card
		const questionDeck = questions
			.filter((q) => isBefore(parseISO(q.launch), new Date()))
			.map((q) => {
				// meta
				const slug = q.slug.current;
				const { label } = q;

				// question
				const { question } = q;
				const { answers } = q;
				const { questionType } = q;

				// covers
				const { coverType } = q;
				const bgVideo = q.coverTypes?.bgVideo?.asset;
				const overflowImage = q.coverTypes?.overflowImage;

				// feedback
				const { answerFeedback } = q;
				const { learnType } = q;
				const learnCategory =
					q.learnTypes._rawLearnInfo?.learnCategory;
				const learnContent =
					q.learnTypes?._rawLearnInfo?.learnContent;
				const learnLink = q.learnTypes?.learnLink;

				const cover = () => {
					if (coverType === ENUMS.COVER_TYPES.VIDEO)
						return bgVideo;
					if (coverType === ENUMS.COVER_TYPES.OVERFLOW)
						return {
							imageURL: overflowImage?.asset.url,
							alt: overflowImage?.alt,
						};
				};

				return (
					<QuestionCard
						// meta
						key={slug}
						slug={slug}
						// question
						question={question}
						answers={answers}
						questionType={questionType}
						// cover
						coverType={coverType}
						color={ENUMS.COLORS[label]}
						cover={cover()}
						// feedback
						answerFeedback={answerFeedback}
						learnType={learnType}
						learnLink={learnLink}
						learnCategory={learnCategory}
						learnContent={learnContent}
					/>
				);
			});

		// create the deck by bookending it with blank cards and adding an intro card
		// the bookends keep everything spaced properly
		const populatedDeck = [
			<BlankCard
				key="bookstart"
				label={copy.labels.when.electing}
			/>,
			<IntroCard
				key="intro"
				label={copy.labels.when.electing}
			/>,
			...questionDeck,
			<EndCard key="end" label={copy.labels.when.electing} />,
			<BlankCard
				key="bookend"
				label={copy.labels.when.electing}
			/>,
		];

		// shuffle in some PSAs and CTAs

		// if the user hasn't signed the pledge, give them a pledge card
		// if the visitor is from social, give a sign the pledge card immediately after the first card
		if (!hasSigned) {
			const d = populatedDeck.findIndex(
				(card) => card.key === social
			);

			populatedDeck.splice(
				d >= 0 ? d + 1 : 4,
				0,
				<PledgeCard
					key="pledge"
					label={copy.labels.when.electing}
				/>
			);
		}

		// if the visitor is anonymous, give a register card after 5 questions
		if (isAnonymous) {
			populatedDeck.splice(
				7,
				0,
				<RegisterCard
					key="register"
					label={copy.labels.when.electing}
				/>
			);
		}

		setDeck(populatedDeck);
	}, [isAnonymous, hasSigned]);

	return deck;
}
