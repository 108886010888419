import React, { useRef, useState, useEffect } from "react";
import tw, { css } from "twin.macro";

import ReactPlayer from "react-player";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo as restartIcon } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle as playIcon } from "@fortawesome/free-regular-svg-icons";

import { isMobile } from "react-device-detect";

const MuxPlayer = ({
	source,
	posterFrame,
	finalFrame,
	hasPlayedCallback = () => {
		// console.log(`Video is done`);
	},
	isPlayingCallback = (playing) => {
		// console.log(`Video is playing?`, playing);
	},
}) => {
	const ref = useRef();

	const [hasPlayed, setHasPlayed] = useState(false);
	useEffect(() => {
		hasPlayedCallback(hasPlayed);
	}, [hasPlayed]);

	const [isPlaying, setIsPlaying] = useState(false);
	useEffect(() => {
		isPlayingCallback(isPlaying);
	}, [isPlaying]);

	return (
		<div
			css={[
				css`
					video:first-of-type {
						${tw`
						absolute h-full w-full 
						bg-black object-cover overflow-hidden 
						rounded-card
					`}
					}
				`,
				tw`z-0`,
			]}
		>
			<ReactPlayer
				ref={ref}
				url={source}
				width="100%"
				height="100%"
				style={tw`absolute h-full w-full object-cover overflow-hidden rounded-card`}
				light={
					isMobile
						? false
						: hasPlayed
						? finalFrame
						: posterFrame
				}
				playing={!isMobile}
				controls={isMobile}
				onEnded={() => {
					setHasPlayed(true);
					ref.current.showPreview();
					setIsPlaying(false);
				}}
				onPlay={() => {
					// setHasPlayed(false);
					setIsPlaying(true);
				}}
				playIcon={
					hasPlayed ? (
						<div
							css={tw`h-full py-5 flex flex-col justify-start`}
						>
							<FontAwesomeIcon
								icon={restartIcon}
								size="2x"
								css={tw`text-white`}
							/>
						</div>
					) : (
						<div
							css={tw`h-full py-20 flex flex-col justify-end`}
						>
							<FontAwesomeIcon
								icon={playIcon}
								size="4x"
								css={tw`text-white`}
							/>
						</div>
					)
				}
			/>
		</div>
	);
};

export default MuxPlayer;
