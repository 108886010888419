import React from "react";
import tw, { css } from "twin.macro";
import { Link } from "gatsby";

import copy from "../../assets/copy";

import logoURL from "../../assets/img/brand/PenLogo.png";
import { ENUMS, COLORS, BASE_WIDTH } from "../../libs/globals";
import { updatePlayerState } from "../../libs/auth";

const HeaderBar = ({ when = `ELECTING`, width }) => {
	const scale = width / BASE_WIDTH;

	return (
		<div
			css={[
				tw`
				container flex justify-between
				font-centra
				leading-4
				whitespace-no-wrap
				py-2
			`,
				css`
					transform: scale(${scale});
				`,
			]}
		>
			<div tw="w-1/2 py-2">
				<h1
					css={[
						tw`
							font-bold uppercase
							text-white
							text-sm
						`,
					]}
				>
					{copy.labels.expect}
				</h1>
				<h2
					css={[
						tw`
							italic font-bold
							text-sm
						`,
						COLORS[ENUMS.COLORS[when]].tagline,
					]}
				>
					{copy.labels.when[when].text}
				</h2>
			</div>

			<Link css={[tw`relative w-1/6`]} to="/quiz">
				<div
					css={[
						tw`w-full h-full`,
						css`
							background-image: url("${logoURL}");
							background-position: right;
							background-size: contain;
							background-repeat: no-repeat;
						`,
					]}
				/>
			</Link>
		</div>
	);
};

export default HeaderBar;
