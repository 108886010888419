import { useEffect, useState } from "react";

export default ({ startIndex, cardDeck, slug, setSlug }) => {
	// ========= STATE =========
	// set the current question key to state
	const [
		currentQuestionIndex,
		setCurrentQuestionIndex,
	] = useState(startIndex);

	useEffect(() => {
		setCurrentQuestionIndex(
			Number.isNaN(startIndex) ? 1 : startIndex
		);
	}, [startIndex]);

	// ========= PARAMS =========
	// when the question changes, change the param
	useEffect(() => {
		if (!cardDeck) return;
		setSlug(cardDeck[currentQuestionIndex].key);
	}, [currentQuestionIndex]);

	// when the param changes, change the current question
	useEffect(() => {
		if (!cardDeck) return;
		setCurrentQuestionIndex(
			Math.max(
				cardDeck.findIndex((card) => card.key === slug),
				1
			)
		);
	}, [slug, cardDeck]);

	// ========= RETURN =========
	return [currentQuestionIndex, setCurrentQuestionIndex];
};
